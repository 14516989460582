.custom_input {
	appearance: none;
	border: none;
	background: $light_grey;
	height: 35px;
	padding: 5px 15px;
	outline: none;
	border-radius: 3px; }

.form-warn {
	display: block;
	position: absolute;
	font-size: 10px;
	top: 100%;
	padding-top: 2px;
	&:before {
		content: "*";
		margin-right: 3px;
		position: relative;
		display: inline-block; }

	@include mod('error') {
		color: red; }

	@include mod('relative') {
		position: relative;
		padding-bottom: 10px; } }
