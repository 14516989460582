.card {
	display: inline-block;
	position: relative;
	background: #FFF;
	padding: 30px;
	border-radius: 3px;

	@include mod('shadow') {
		box-shadow: 0 10px 15px 0px rgba(#000, 0.3); }

	@include mod('rounded') {
		border-radius: 8px; } }
