.main-header {
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: $header_height;
	padding: 15px $side_padding;
	background: pink;
	z-index: 9999;
	@include clearfix; }

.header-left {
	display: inline-block;
	float: left; }

.header-right {
	display: inline-block;
	float: right; }

.header-logout {
	margin-right: 25px;
	@include elem('icon') {
		display: inline-block;
		position: relative;
		bottom: 2px;
		fill: #000;
		margin-right: 15px;
		width: 20px;
		height: 20px;
		vertical-align: middle;
		transform: translateX(0px);
		transition: transform 0.15s $cubic; }

	&:hover {
		.header-logout__icon {
			transform: translateX(5px); } } }
