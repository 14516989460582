body {
	font-family: $main_ft;
	display: block;
	width: 100%;
	height: 100%; }

a {
	text-decoration: none;
	&:hover,
	&:focus,
	&:active {
		color: initial;
		text-decoration: none; } }

// Resets main text tag margins
h1, h2, h3, h4, h5, h6, p {
	margin: 0; }

th, td {
	padding: 5px 10px;
	border: 1px solid #ccc;
	min-width: 100px;
	text-align: center; }
