body.login {
	background: $c-primary; }
body.forgotten_password {
	background: red; }
body.register {
	background: green; }
body.new_password {
	background: yellow; }



.out-app-window {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: $mobile_width;
	max-width: 350px;
	@include mod('register') {
		max-width: 600px; }

	@include elem('message') {
		text-align: center;
		margin-bottom: 40px;
		font-size: 18px;
		a {
			text-decoration: underline;
			color: $c-primary; } }

	@include elem('logo') {
		width: 90%;
		max-width: 200px;
		display: block;
		position: relative;
		margin: 0 auto 40px; }

	@include elem('input-container') {
		display: block;
		position: relative;
		margin-bottom: 20px;
		@include mod('register') {
			@include clearfix; }
		@include mod('new-pass') {
			.new-pass_okt-password-repeater {
				width: 100%;
				float: none; } } }

	@include elem('input') {
		display: block;
		width: 100%;
		margin-bottom: 20px;
		&:last-child {
			margin-bottom: 0; }
		@include mod('register') {
			width: 48%;
			position: relative;
			float: left;
			display: inline-block;
			margin-bottom: 20px;
			&:nth-child(odd) {
				margin-right: 4%; } } }

	okt-password-repeater {
		display: inline-block;
		float: left;
		width: 48%;
		input {
			width: 100% !important;
			&:last-child {
				margin-bottom: 20px; } } }


	@include elem('button-container') {
		@include clearfix;
		margin-bottom: 20px;
		@include mod('center') {
			text-align: center;
			.button {
				float: none !important;
				margin: 0 !important; } } }

	@include elem('button') {
		background: $c-primary;
		width: 48%;
		display: inline-block;
		float: left;
		color: #FFF;
		font-size: 12px;
		letter-spacing: 0.5px;
		font-weight: 100;
		&:first-child {
			margin-right: 4%; }
		&:hover {
			color: #FFF; }
		@include mod('full') {
			width: 100%;
			margin: 0; } }

	@include elem('bottom') {
		text-align: center; }
	@include elem('link') {
		&:hover {
			text-decoration: underline; } }

	.form-warn {
		float: left; } }
