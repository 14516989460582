// BEM mixins
@mixin elem($el) {
	&__#{ $el } {
		@content; } }

@mixin mod($mod) {
	&--#{$mod} {
		@content; } }


// Creates a shorthand for @extend
@mixin ext( $extender ) {
	@extend .#{$extender}; }



// Basics styles
@mixin noListStyle() {
	list-style: none;
	padding: 0;
	margin: 0; }

@mixin maxWdith($center:false) {
	display: block;
	position: relative;
	max-width: $max-width;
	width: 100%;
	@if $center {
		margin: 0 auto; } }
