// Open Sans
@import 'https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700';
$main_ft: 'Open Sans', sans-serif;

// // Roboto
// @import 'https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700';
// $main_ft: 'Roboto Condensed', sans-serif;

// // Rubik
// @import 'https://fonts.googleapis.com/css?family=Rubik:300,400,500,700';
// $main_ft: 'Rubik', sans-serif;

// // Montserrat
// @import 'https://fonts.googleapis.com/css?family=Montserrat:400,700';
// $main_ft: 'Montserrat', sans-serif;
