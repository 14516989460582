// Main page settings
$max-width: 1200px;
$mobile_width: 95%;

$header_height: 60px;
$side_padding: 20px;

// Animations
$cubic: cubic-bezier(.55,0,.1,1);
$cubicElastic: cubic-bezier(.55,0,.1,1.5);

// Colors
$c-primary: #0062f1;
$c-primary--dark: #0a2343;

$light_grey: #F3F3F3;

$pass_green: #AFDD1A;
$pass_red: #DF3F1B;
