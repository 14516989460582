okt-burger {
	display: inline-block;
	position: relative;
	padding: 5px;
	vertical-align: middle;
	z-index: 9999;

	.burger {
		display: block;
		position: relative;
		width: 30px;
		height: 20px;
		box-sizing: content-box;
		cursor: pointer;
		// overflow-x: hidden
		.bar {
			display: block;
			position: absolute;
			left: 0;
			background: #FFF;
			width: 100%;
			height: 2px;
			opacity: 1;
			transform: rotate(0deg) translateX(0) translateY(0);
			transition: all 0.25s $cubic;
			&.top_bar {
				top: 0; }
			&.mid_bar {
				top: 50%;
				margin-top: -1px; }
			&.btm_bar {
				bottom: 0; } }
		&:hover {
			.bar {
				&.top_bar {
					transform: translateY(-2px); }
				&.btm_bar {
					transform: translateY(2px); } } }

		&.opened {
			.bar {
				&.top_bar {
					transform: translateY(9px) rotate(45deg); }
				&.mid_bar {
					transform: translateX(-100%);
					opacity: 0; }
				&.btm_bar {
					transform: translateY(-9px) rotate(-45deg); } } } } }

