.domaine-form {
	display: block;
	position: relative;

	.input-container {
		margin-bottom: 10px;

		label,
		input,
		summernote {
			display: block; }

		input {
			width: 200px; } } }
