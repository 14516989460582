.confirm {
    position: fixed;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(#000, 0.4);
    display: flex;
    justify-content: center;
    align-items: center; }

.confirm-box {
    position: relative;
    display: block;
    max-width: 500px;
    width: 95%;
    background: #FFF;
    padding: 40px 60px;
    text-align: center;
    border-radius: 3px;
    font-size: 16px;
    opacity: 0;
    @include mod('rounded') {
        border-radius: 8px; }
    @include mod('shadowed') {
        box-shadow: 0 10px 15px 3px rgba(0,0,0,0.2); }


    @include elem('message') {
        margin-bottom: 25px; }

    @include elem('btns-container') {
        @include clearfix(); }

    @include elem('button') {
        display: inline-block;
        background: $c-primary;
        width: 150px;
        color: #FFF;
        box-shadow: 0px 0px 0px 0px rgba(#000, 0);
        transform: scale(1);
        transition: all 0.25s $cubic;
        user-select: none;
        &:hover {
            box-shadow: 0px 2px 5px 0px rgba(#C6DFF1, 0.5);
            transform: scale(1.05); }
        @include mod('margin-right') {
            margin-right: 15px; } }


    @media (max-width: 500px) {
        padding: 40px;

        @include elem('button') {
            display: block;
            @include mod('margin-right') {
                margin-right: 0;
                margin-bottom: 15px; } } } }
