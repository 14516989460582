.max-width {
	@include maxWdith(); }

.app-view {
	display: block;
	position: relative;
	width: 100%;
	min-height: 100vh;
	overflow-x: scroll; }


.logo {
	max-width: 100%; }

ul, ol {
	&.nostyle {
		list-style: none;
		padding: 0;
		margin: 0; } }

.main-container {
	position: relative;
	padding-top: $header_height;

	.page-container {
		position: relative;
		display: block;
		width: 100%;
		max-width: $max_width;
		padding: $side_padding; } }


.title-container {
	margin-bottom: 25px; }


section {
	padding: 15px 0; }

.section-title {
	margin: 10px 0; }

.modal-dialog {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%) !important; }


// Replaces browser-sync message
#__bs_notify__ {
	top: auto !important;
	right: auto !important;
	bottom: 0 !important;
	left: 0 !important; }
