.button {
	display: inline-block;
	position: relative;
	cursor: pointer;
	transform: scale(1);
	transition: all 0.15s $cubic;
	will-change: transform;
	border-radius: 3px;
	padding: 7px;
	text-align: center;
	&:hover,
	&:focus {
		transform: scale(1.05); }

	@include mod('nostyle') {
		outline: none;
		border: none;
		background: #FFF; }

	@include mod('primary') {
		background: $c-primary;
		color: text-contrast($c-primary); } }
